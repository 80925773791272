import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import Tile from '../../components/Tile/Tile'

import Crane from '../../svgs/barrier-greyBg.svg'
import Megaphone from '../../svgs/megaphone.svg'

const NewHomeDashboardPage = () => {
  const intl = useIntl()
  return (
    <Layout
      header={
        <span className='bold'>
          {intl.formatMessage({ id: 'complaints.title' })}
        </span>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'complaints.title' })}
      />
      <Container>
        <Row>
          <Col>
            <p>
              <FormattedMessage id='fileComplaint.contentA' />
            </p>
            <p>
              <FormattedMessage id='fileComplaint.contentB' />{' '}
              <Link to='/licensing-compliance/licensed-builder-complaint/'>
                <FormattedMessage id='fileComplaint.contentBii' />
              </Link>
              .
              <br />
              <FormattedMessage id='fileComplaint.contentC' />{' '}
              <Link to='/licensing-compliance/illegal-building-complaint/'>
                <FormattedMessage id='fileComplaint.contentCii' />
              </Link>
              .
            </p>
            <p>
              <FormattedMessage id='fileComplaint.contentD' />{' '}
              <Link to='/licensing-compliance/complaints-process/'>
                <FormattedMessage id='fileComplaint.contentDii' />
              </Link>
              .
            </p>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col xl={6} md={6} sm={12}>
            <Tile
              icon={<Megaphone />}
              link={{
                text: intl.formatMessage({ id: 'complaintForms.licensedLink' }),
                path: '/licensing-compliance/licensed-builder-complaint/'
              }}
            />
          </Col>

          <Col xl={6} md={6} sm={12}>
            <Tile
              icon={<Crane />}
              link={{
                text: intl.formatMessage({ id: 'complaintForms.illegalLink' }),
                path: '/licensing-compliance/illegal-building-complaint/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NewHomeDashboardPage
